/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Paper, Typography } from "@mui/material";
import { useState } from "react";
import LanguageFormWrapper from "../../components/floatingForm";

const Module = ({ module, description, isExpanded }) => {
  const bulletPoints = description.split("\n");

  return (
    <li className="mb-2">
      <Typography
        className="!text-xl !font-montserrat text-red-600"
        gutterBottom
      >
        {module}
      </Typography>
      {isExpanded && (
        <div className="mt-2">
          <ul className="list-disc pl-6">
            {bulletPoints.map((point, index) => (
              <li key={index} className="mb-2 !text-md text-[#2957a4]">
                {point}
              </li>
            ))}
          </ul>
        </div>
      )}
    </li>
  );
};

const CourseLevel = ({ level, modules, duration, setIsOpen }) => {
  const [isExpanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!isExpanded);
  };

  return (
    <Paper className="p-4 my-4 w-1/3 !transition-transform !duration-300 !ease-in-out !transform hover:!translate-y-[-5px] hover:!shadow-lg !rounded-2xl">
      <Typography
        className="!text-2xl !font-bold uppercase text-[#2957a4]"
        gutterBottom
      >
        Level {level}
      </Typography>
      <Typography variant="subtitle1" className="!font-montserrat" gutterBottom>
        Duration: {duration} hours
      </Typography>
      <ol>
        {modules.map(({ module, description }, index) => (
          <Module
            key={index}
            module={module}
            description={description}
            isExpanded={isExpanded}
          />
        ))}
      </ol>
      <div className="flex mt-6 mb-2 w-full !font-montserrat justify-between">
        <Button
          onClick={handleExpandClick}
          variant="outlined"
          size="small"
          className="mt-2 !text-[#2957a4] !font-montserrat hover:!text-red-600"
        >
          {isExpanded ? "Collapse Details" : "Expand Details"}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="mt-2 !bg-[#2957a4] !font-montserrat hover:!bg-red-600"
          onClick={() => {
            setIsOpen(true);
          }}
        >
          Ask for Price
        </Button>
      </div>
    </Paper>
  );
};

const CourseResources = () => {
  return (
    <div className="mt-8">
      <Typography
        className="!text-2xl !font-montserrat text-[#2957a4]"
        gutterBottom
      >
        Resources
      </Typography>
      <ul className="list-disc pl-6">
        <li>
          <a href="#" className="text-[#2957a4]">
            PTE Preparation Tips
          </a>
        </li>
        <li>
          <a className="text-[#2957a4]" href="#">
            Mastering PTE Speaking Section
          </a>
        </li>
        <li>
          <a className="text-[#2957a4]" href="#">
            PTE Writing Strategies
          </a>
        </li>
      </ul>
    </div>
  );
};

const PTE = () => {
  const [isOpen, setIsOpen] = useState(false);
  const levels = [
    {
      level: 1,
      duration: 45,
      modules: [
        {
          module: "Introduction to PTE",
          description:
            "Get acquainted with the format and structure of the PTE exam.",
        },
        {
          module: "Listening Skills",
          description:
            "Develop effective listening skills for the PTE Listening section.",
        },
        {
          module: "Reading Techniques",
          description:
            "Master reading strategies to excel in the PTE Reading section.",
        },
      ],
    },
    {
      level: 2,
      duration: 45,
      modules: [
        {
          module: "Speaking Practice",
          description:
            "Enhance your speaking abilities with PTE Speaking practice sessions.",
        },
        {
          module: "Writing Strategies",
          description:
            "Learn writing techniques for success in the PTE Writing section.",
        },
        {
          module: "Grammar and Vocabulary",
          description:
            "Brush up on grammar and vocabulary crucial for the PTE exam.",
        },
      ],
    },
    {
      level: 3,
      duration: 45,
      modules: [
        {
          module: "Mock Tests and Simulations",
          description:
            "Simulate real exam conditions with PTE mock tests for optimal preparation.",
        },
        {
          module: "Advanced Strategies",
          description:
            "Explore advanced strategies to tackle challenging sections of the PTE exam.",
        },
        {
          module: "Review and Feedback",
          description:
            "Receive personalized feedback to refine your PTE exam performance.",
        },
      ],
    },
  ];

  return (
    <div className="mt-8 px-16 text-gray-800">
      <Typography
        className="!text-3xl !text-center !font-adlam !mb-4 text-[#2957a4]"
        gutterBottom
      >
        PTE Course
      </Typography>

      <div className="flex space-x-6">
        {levels.map(({ level, modules, duration }) => (
          <CourseLevel
            key={level}
            level={level}
            modules={modules}
            duration={duration}
            setIsOpen={setIsOpen}
          />
        ))}
      </div>
      <LanguageFormWrapper isOpen={isOpen} setIsOpen={setIsOpen} />
      <CourseResources />
    </div>
  );
};

export default PTE;
