import React from "react";
import { Link, useNavigate } from "react-router-dom";
import lingoHiveImage from "../../images/logo.png"; // Replace with the actual path to your image
import maaSarasvati from "../../images/maaSarasvati.png"; // Replace with the actual path to your image

// Include the Google Fonts link here
const FontsLink = () => (
  <link
    rel="stylesheet"
    href="https://fonts.googleapis.com/css2?family=ADLaM+Display&display=swap"
  />
);

const Header = () => {
  const navigate = useNavigate();
  return (
    <header className="text-gray-600 body-font">
      <div className="container justify-center mx-auto flex flex-wrap p-5 flex-col items-center">
        <div className="flex items-center justify-between w-full space-x-3 md:text-left">
          <Link
            to="/"
            className="flex items-center md:items-start text-gray-900"
          >
            <img
              className="w-20 h-20 mt-2 rounded-full mb-2  border-gray-200"
              src={lingoHiveImage}
              alt="The Lingo Hive Logo"
            />
          </Link>
          <div>
            <span className="text-5xl text-[#2957a4] font-body font-adlam">
              The <span className="text-red-600">Lingo</span> Hive{" "}
            </span>
          </div>

          <div className="flex items-center">
            {/* <span class="inline-flex right-4 sm:ml-auto sm:mt-0 mt-4">
              <Link clLinkss="ml-3 text-blue-900  hover:text-red-900">
                <button>Login</button>
              </Link>
              <span className="text-blue-900 pl-4"> | </span>
              <Link class="ml-3 text-blue-900  hover:text-red-900">
                <button>Register</button>
              </Link>
            </span> */}
            <img
              className="w-20 h-20 object-contain mt-2 rounded-full mb-2  border-gray-200"
              src={maaSarasvati}
              alt="The Lingo Hive Logo"
            />
          </div>
        </div>

        <nav className="w-full md:py-1 flex whitespace-nowrap items-center text-base justify-center">
          <Link
            to="/"
            className="mr-5 hover:text-red-900  text-blue-900  !transition-transform !duration-300 !ease-in-out !transform hover:!translate-y-[-2px] hover:!drop-shadow-lg"
          >
            Home
          </Link>
          <span className="text-blue-900 pr-4"> | </span>
          <Link
            to="/courses/spoken-english"
            className="mr-5 hover:text-red-900  text-blue-900 !transition-transform !duration-300 !ease-in-out !transform hover:!translate-y-[-2px] hover:!drop-shadow-lg "
            onClick={() => {
              navigate("/courses/spoken-english");
            }}
          >
            Spoken English
          </Link>
          <span className="text-blue-900 pr-4"> | </span>
          <Link
            to="/courses/ielts"
            className="mr-5 hover:text-red-900  text-blue-900 !transition-transform !duration-300 !ease-in-out !transform hover:!translate-y-[-2px] hover:!drop-shadow-lg"
          >
            IELTS
          </Link>
          <span className="text-blue-900 pr-4"> | </span>
          <Link
            to="/courses/pte"
            className="mr-5 hover:text-red-900  text-blue-900 !transition-transform !duration-300 !ease-in-out !transform hover:!translate-y-[-2px] hover:!drop-shadow-lg"
          >
            PTE
          </Link>
          <span className="text-blue-900 pr-4"> | </span>
          <Link
            to="/courses/grammar"
            className="mr-5 hover:text-red-900  text-blue-900 !transition-transform !duration-300 !ease-in-out !transform hover:!translate-y-[-2px] hover:!drop-shadow-lg"
          >
            Grammar
          </Link>
          <span className="text-blue-900 pr-4"> | </span>
          <Link
            to="/courses/business-english"
            className="mr-5 hover:text-red-900  text-blue-900 !transition-transform !duration-300 !ease-in-out !transform hover:!translate-y-[-2px] hover:!drop-shadow-lg"
          >
            Professional English
          </Link>
          {/* <span className="text-blue-900 pr-4"> | </span>
          <Link
            to="/english-for-nurses"
            className="mr-5 hover:text-red-900  text-blue-900 !transition-transform !duration-300 !ease-in-out !transform hover:!translate-y-[-2px] hover:!drop-shadow-lg"
          >
            English For Nurses
          </Link> */}
          <span className="text-blue-900 pr-4"> | </span>
          <Link
            to="/downloads"
            className="mr-5 hover:text-red-900  text-blue-900 !transition-transform !duration-300 !ease-in-out !transform hover:!translate-y-[-2px] hover:!drop-shadow-lg"
          >
            Downloads
          </Link>
          <span className="text-blue-900 pr-4"> | </span>
          <Link
            to="/blog"
            className="mr-5 hover:text-red-900  text-blue-900 !transition-transform !duration-300 !ease-in-out !transform hover:!translate-y-[-2px] hover:!drop-shadow-lg"
          >
            Blog
          </Link>
          <span className="text-blue-900 pr-4"> | </span>
          <Link
            to="/careers"
            className="mr-5 hover:text-red-900  text-blue-900 !transition-transform !duration-300 !ease-in-out !transform hover:!translate-y-[-2px] hover:!drop-shadow-lg"
          >
            Careers
          </Link>
          <span className="text-blue-900 pr-4"> | </span>
          <Link
            to="/online-courses"
            className="mr-5 hover:text-red-900  text-blue-900 !transition-transform !duration-300 !ease-in-out !transform hover:!translate-y-[-2px] hover:!drop-shadow-lg"
          >
            Online Courses
          </Link>
        </nav>
      </div>
    </header>
  );
};

export default Header;
