/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Paper, Typography } from "@mui/material";
import { useState } from "react";
import LanguageFormWrapper from "../../components/floatingForm";

const Module = ({ module, description, isExpanded }) => {
  const bulletPoints = description.split("\n");

  return (
    <li className="mb-2">
      <Typography
        className="!text-xl !font-montserrat text-red-600"
        gutterBottom
      >
        {module}
      </Typography>
      {isExpanded && (
        <div className="mt-2">
          <ul className="list-disc pl-6">
            {bulletPoints.map((point, index) => (
              <li key={index} className="mb-2 !text-md text-[#2957a4]">
                {point}
              </li>
            ))}
          </ul>
        </div>
      )}
    </li>
  );
};

const CourseLevel = ({ level, modules, duration, setIsOpen }) => {
  const [isExpanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!isExpanded);
  };

  return (
    <Paper className="p-4 my-4 w-1/3 !transition-transform !duration-300 !ease-in-out !transform hover:!translate-y-[-5px] hover:!shadow-lg !rounded-2xl">
      <Typography
        className="!text-2xl !font-bold uppercase text-[#2957a4]"
        gutterBottom
      >
        Level {level}
      </Typography>
      <Typography variant="subtitle1" className="!font-montserrat" gutterBottom>
        Duration: {duration} hours
      </Typography>
      <ol>
        {modules.map(({ module, description }, index) => (
          <Module
            key={index}
            module={module}
            description={description}
            isExpanded={isExpanded}
          />
        ))}
      </ol>
      <div className="flex mt-6 mb-2 w-full !font-montserrat justify-between">
        <Button
          onClick={handleExpandClick}
          variant="outlined"
          size="small"
          className="mt-2 !text-[#2957a4] !font-montserrat hover:!text-red-600"
        >
          {isExpanded ? "Collapse Details" : "Expand Details"}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="mt-2 !bg-[#2957a4] !font-montserrat hover:!bg-red-600"
          onClick={() => {
            setIsOpen(true);
          }}
        >
          Ask for Price
        </Button>
      </div>
    </Paper>
  );
};

const ProfessionalEnglishCourse = () => {
  const [isOpen, setIsOpen] = useState(false);
  const levels = [
    {
      level: 1,
      duration: 30,
      modules: [
        {
          module: "Business Communication Basics",
          description:
            "Develop a strong foundation in effective business communication.",
        },
        {
          module: "Professional Email Writing",
          description:
            "Master the art of composing professional and concise emails.",
        },
        {
          module: "Telephonic Etiquette",
          description:
            "Learn the principles of polite and effective communication over the phone.",
        },
      ],
    },
    {
      level: 2,
      duration: 30,
      modules: [
        {
          module: "Negotiation Skills for Professionals",
          description:
            "Learn strategies and techniques for successful negotiations.",
        },
        {
          module: "Effective Presentation Skills",
          description:
            "Enhance your ability to deliver impactful and engaging presentations.",
        },
        {
          module: "Business Report Writing",
          description:
            "Master the art of crafting clear and concise business reports.",
        },
      ],
    },
    {
      level: 3,
      duration: 30,
      modules: [
        {
          module: "Corporate Etiquette and Protocol",
          description:
            "Understand and practice corporate etiquette for professional settings.",
        },
        {
          module: "Advanced Business Writing",
          description:
            "Improve your business writing skills for reports, proposals, and more.",
        },
        {
          module: "Effective Team Communication",
          description:
            "Learn strategies for fostering open and effective communication within teams.",
        },
      ],
    },
  ];

  return (
    <div className="mt-8 px-16 text-gray-800">
      <Typography
        className="!text-3xl !text-center !font-adlam !mb-4 text-[#2957a4]"
        gutterBottom
      >
        Professional English Course for Business and Corporates
      </Typography>

      <div className="flex space-x-6">
        {levels.map(({ level, modules, duration }) => (
          <CourseLevel
            key={level}
            level={level}
            modules={modules}
            duration={duration}
            setIsOpen={setIsOpen}
          />
        ))}
      </div>
      <LanguageFormWrapper isOpen={isOpen} setIsOpen={setIsOpen} />
      <div className="mt-8">
        <Typography
          className="!text-2xl !font-montserrat text-[#2957a4]"
          gutterBottom
        >
          Resources
        </Typography>
        <ul className="list-disc pl-6">
          <li>
            <a href="#" className="text-[#2957a4]">
              Business Communication Tips{" "}
            </a>
          </li>
          <li>
            <a className="text-[#2957a4]" href="#">
              Guide to Professional Email Etiquette{" "}
            </a>
          </li>
          <li>
            <a className="text-[#2957a4]" href="#">
              Effective Presentation Techniques{" "}
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ProfessionalEnglishCourse;
