import React from "react";
import { Link } from "react-router-dom";
import lingoHiveImage from "../../images/logo.png"; // Replace with the actual path to your image

const Footer = () => {
  return (
    <footer className="text-gray-600 body-font">
      <div className="container px-5 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
        <div className="w-64 flex items-center space-x-3 flex-shrink-0 mx-auto text-center md:text-left">
          <Link
            to="/"
            className="flex items-center md:items-start text-gray-900"
          >
            <img
              className="w-16 h-16 mt-2 rounded-full mb-2 border-4 border-gray-200"
              src={lingoHiveImage}
              alt="The Lingo Hive Logo"
            />
          </Link>
          <div>
            <span className="text-xl text-red-900">The Lingo Hive... </span>
            <p className="mt-0 text-sm text-blue-900">Experience Excellence</p>
          </div>
        </div>

        <div className="flex-grow flex flex-wrap md:pl-20 -mb-10 md:mt-0 mt-10 md:text-left text-center">
          <div className="lg:w-1/4 md:w-1/2 w-full px-4">
            <h2 className="title-font font-medium text-blue-900 tracking-widest text-sm mb-3">
              Our Courses
            </h2>
            <nav className="list-none mb-10">
              <li>
                <Link to="/ielts" className="text-gray-600 hover:text-gray-800">
                  IELTS
                </Link>
              </li>
              <li>
                <Link to="/pte" className="text-gray-600 hover:text-gray-800">
                  PTE
                </Link>
              </li>
              <li>
                <Link
                  to="/spokenEnglish"
                  className="text-gray-600 hover:text-gray-800"
                >
                  Spoken English
                </Link>
              </li>
              <li>
                <Link
                  to="/businessEnglish"
                  className="text-gray-600 hover:text-gray-800"
                >
                  Business English
                </Link>
              </li>
              {/* Add more customer-related links */}
            </nav>
          </div>
          <div className="lg:w-1/4 md:w-1/2 w-full px-4">
            <h2 className="title-font font-medium text-blue-900 tracking-widest text-sm mb-3">
              About Us
            </h2>
            <nav className="list-none mb-10">
              <li>
                <Link to="/ielts" className="text-gray-600 hover:text-gray-800">
                  Our Mission
                </Link>
              </li>
              <li>
                <Link to="/pte" className="text-gray-600 hover:text-gray-800">
                  Our Vision
                </Link>
              </li>
              <li>
                <Link
                  to="/spokenEnglish"
                  className="text-gray-600 hover:text-gray-800"
                >
                  Our Team
                </Link>
              </li>
              <li>
                <Link
                  to="/businessEnglish"
                  className="text-gray-600 hover:text-gray-800"
                >
                  Testimonials
                </Link>
              </li>
              {/* Add more customer-related links */}
            </nav>
          </div>
        </div>
      </div>

      <div className="bg-gray-100">
        <div className="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
          <p className="text-blue-900 text-sm text-center sm:text-left">
            © 2024 E-World —
            <a
              href="https://twitter.com/knyttneve"
              rel="noopener noreferrer"
              className="text-blue-900 ml-1"
              target="_blank"
            >
              @TheLingoHive
            </a>
          </p>
          <span class="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
            {/* <a class="text-blue-500">
              <svg
                fill="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                class="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
              </svg>
            </a>
            <a class="ml-3 text-blue-500">
              <svg
                fill="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                class="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
              </svg>
            </a> */}
            <a
              href="https://www.instagram.com/thelingohive?igsh=YTEwdnd0dW00N3Fi"
              target="_blank"
              className="ml-3 text-blue-500"
            >
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                class="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
              </svg>
            </a>

            {/* <a class="ml-3 text-blue-500">
              <svg
                fill="currentColor"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="0"
                class="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="none"
                  d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"
                ></path>
                <circle cx="4" cy="4" r="2" stroke="none"></circle>
              </svg>
            </a> */}
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
