import React from "react";
import lingoHiveImage from "../../../images/logo.png"; // Replace with the actual path to your image
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const AboutSection = () => {
  const imageUrls = [
    "https://lh3.googleusercontent.com/p/AF1QipN7p3pjpbXpXmjF8HyQjA1z33PaIYHak1oEDmGK=s1360-w1360-h1020",
    "https://lh3.googleusercontent.com/p/AF1QipN1X4Xkt9anwWy0UliE5Qqv5hkDJ9BFJ2D2pHW5=s1360-w1360-h1020",
    "https://lh3.googleusercontent.com/p/AF1QipMpJuR8jF5ACqYzVAM4EKBwg4kYTjqtqcYoLT7a=s1360-w1360-h1020",
  ];

  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000, // Adjust the speed as needed
  };

  return (
    <section className=" from-gray-200 via-white to-gray-200 text-gray-800 pb-10">
      {/* <div className="container mx-auto px-10 flex flex-col items-center">
        <img
          className="w-32 h-32 rounded-full border-4 border-gray-200"
          src={lingoHiveImage}
          alt="The Lingo Hive Logo"
        />
        <div className=" bg-opacity-25 rounded-xl">
          <h2 className="text-3xl font-bold mb-4 text-center text-red-600">
            The <span className="text-[#2957a4]">Lingo</span> Hive
          </h2>
        </div>
      </div> */}
      <Slider {...settings}>
        {imageUrls.map((url, index) => (
          <div key={index} className="h-[500px] bg-center opacity-90 bg-cover">
            <img
              src={url}
              alt={`carousel-${index}`}
              className="w-full h-full object-cover"
            />
          </div>
        ))}
      </Slider>

      <div className="pt-10 px-6 bg-opacity-25 rounded-xl">
        <h2 className="text-3xl font-bold mb-4 text-center text-red-600">
          Who <span className="text-[#2957a4]">We</span> Are
        </h2>
        <p className="text-lg leading-loose mb-4 text-center text-[#2957a4]">
          A Product of E-<span className="text-red-600">World</span> - The
          English World, The Lingo Hive has been a beacon of foreign languages
          for more than 14 years. We specialize in crafting fluency, instilling
          confidence, and redefining language journeys. Join TLH for language
          programs tailored to empower learner's needs in the globalized world.
          Our experienced instructors and innovative courses promise triumph in
          exams, elevate business communication, and enrich personal growth.
        </p>
      </div>
    </section>
  );
};

export default AboutSection;
