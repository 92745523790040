import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const RenderTestimonials = (
  { content, author, role, image },
  index
  // showFull,
  // setShowFull
) => {
  const [showFull, setShowFull] = useState(false);
  return (
    <div key={index} className="p-4 flex w-full">
      <div
        className={`${
          showFull ? "h-fulls" : "h-[400px]"
        } bg-gray-100 p-8 rounded relative`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          className="block w-5 h-5 text-gray-400 mb-4"
          viewBox="0 0 975.036 975.036"
        >
          <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
        </svg>
        <p
          className={`leading-relaxed mb-6 ${showFull ? "" : "line-clamp-[9]"}`}
        >
          {content}
        </p>
        <div className="inline-flex justify-between w-full items-center">
          <img
            alt={`testimonial-${index}`}
            src={image}
            className="w-12 h-12 rounded-full flex-shrink-0 object-cover object-center"
          />
          <span className="flex-grow flex flex-col pl-4">
            <span className="title-font font-medium text-gray-900">
              {author}
            </span>
            <span className="text-gray-500 text-sm">{role}</span>
          </span>
        </div>
        {content?.length > 380 &&<button
          className="text-red-500 absolute right-4 bottom-1 focus:outline-none rounded h-10 text-xs"
          onClick={() => {
            setShowFull(!showFull);
          }}
        >
          {showFull ? "Collapse" : "Expand"} {"-->"}
        </button>}
      </div>
    </div>
  );
};

const Testimonial = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const testimonials = [
    {
      content:
        "E-World is a very good institute for learning English. Teachers are so professional here; you can learn from them. They are so supportive and friendly. Gulfam Sir is very supportive; he always encourages me to speak. His classes are so interactive. He is not like other teachers; he always ensures that every student should improve. Because of him, I really improved my English and gained confidence. I can clearly see the visible difference in me.",
      author: "Tanu chauhan",
      role: "IELTS Student",
      image: "https://dummyimage.com/106x106",
    },
    {
      content:
        "I recently took an IELTS class and couldn't be happier with the experience. The instructors were highly knowledgeable and provided personalized attention to each student. The class structure was well-organized, covering all sections of the exam thoroughly. I particularly appreciated the focus on practical strategies for each section, which greatly improved my confidence and performance. The study materials provided were comprehensive and up-to-date, aiding in my preparation. Overall, I highly recommend this E-world class to anyone looking to achieve their desired score.",
      author: "Sapna Kinha",
      role: "IELTS Student",
      image: "https://dummyimage.com/107x107",
    },
    {
      content:
        "E World is the renowned coaching center for IELTS and spoken English and Rohit sir owner of this institute moreover they teach quite interesting and in so simple way. So I finally recommended this centre for every students who need that exam and English skills also.",
      author: "Manish Saini",
      role: "IELTS Student",
      image: "https://dummyimage.com/107x107",
    },
    {
      content:
        "Being from Haryana, where English wasn't a regular part of my background, I was always scared of the language. When preparing for interviews during my college days in 2022, I realized the need to enhance my spoken English skills. That's when I first joined EWorld. After a successful stint, I cracked interviews and stepped into the corporate world as a software engineer. However, as a year passed, I noticed my proficiency getting a bit rusty, recognizing the importance of continuous improvement, I decided to rejoin EWorld in October 2023. Under the guidance of a remarkable mentor at EWorld, my language skills flourished once more, extending beyond interviews into the corporate realm. This mentor, my Guruji, Mr. Rohit, played a pivotal role in not only refining my communication but also aiding in personal growth, helping me become a better person, a superior version of myself. His classes have been invaluable on this journey of linguistic and overall development. While there are many coaching centers at Old DLF, I'd suggest having a one-to-one interview with Mr. Rohit before deciding.",
      author: "Deepak Sharma",
      role: "Spoken English Student",
      image: "https://dummyimage.com/107x107",
    },
    {
      content:
        "E world is best institute for spoken English .The teaching methods are effective they also focus on public speaking and help in boosting confidence level .Rohit sir and team are supportive. I will highly recommend E world for anyone looking to enchance their communication skills.",
      author: "Suman Wadhwa",
      role: "IELTS Student",
      image: "https://dummyimage.com/107x107",
    },
    // Add more testimonials as needed
  ];

  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 pb-16 mx-auto">
        <h1 className="text-3xl font-medium title-font text-red-600 mb-12 text-center">
          Testimonials
        </h1>
        <div className="flex -m-4">
          <div className="container px-5 mx-auto">
            <div className="slick-container">
              <Slider {...settings} className="slick-slider">
                {testimonials.map((testimonial, index) =>
                  RenderTestimonials(testimonial, index)
                )}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
