import { default as React, useRef } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const CoursesSection = () => {
  const sliderRef = useRef(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const coursesData = [
    {
      title: "IELTS Preparation",
      icon: (
        <svg
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          className="w-5 h-5"
          viewBox="0 0 24 24"
        >
          <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
        </svg>
      ),
      description:
        "Comprehensive IELTS(General Training + Academic) preparation course covering all sections with practice tests and expert guidance.",
    },
    {
      title: "PTE Exam Training",
      icon: (
        <svg
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          className="w-5 h-5"
          viewBox="0 0 24 24"
        >
          <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
          <circle cx="12" cy="7" r="4"></circle>
        </svg>
      ),
      description:
        "Prepare for PTE exam with specialized training, mock exams, and personalized feedback.",
    },
    {
      title: "Business English",
      icon: (
        <svg
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          className="w-5 h-5"
          viewBox="0 0 24 24"
        >
          <circle cx="6" cy="6" r="3"></circle>
          <circle cx="6" cy="18" r="3"></circle>
          <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
        </svg>
      ),
      description:
        "Enhance your business communication skills with our Business English course.",
    },
    // Add other courses here
    {
      title: "Spoken English",
      icon: (
        <svg
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          className="w-5 h-5"
          viewBox="0 0 24 24"
        >
          {/* ... (path for the Spoken English icon) */}
        </svg>
      ),
      description:
        "Improve your spoken English skills through interactive sessions and practical exercises.",
    },
    {
      title: "Corporate Training",
      icon: (
        <svg
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          className="w-5 h-5"
          viewBox="0 0 24 24"
        >
          {/* ... (path for the English for Nurses icon) */}
        </svg>
      ),
      description:
        "Tailored Communication Skills and Soft Skills Course designed to meet the corporate needs of professionals and businesses.",
    },
    {
      title: "Grammar",
      icon: (
        <svg
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          className="w-5 h-5"
          viewBox="0 0 24 24"
        >
          <path d="M3 3h18v18H3z"></path>
          <path d="M17 8h-3m0 0H8m0 0H7m0 0H5m0 0H3m0 0v2m0 0v2m0 0v2m0 0v2m0 0v2m0 0v2m0 0v2m0 0V3m0 0h2m0 0h2m0 0h2m0 0h2m0 0h2m0 0h2m0 0h2m0 0h2m0 0h2m0 0h2m0 0h2"></path>
        </svg>
      ),
      description:
        "Master the intricacies of English grammar with our comprehensive Grammar Course.",
    },
  ];

  const renderFeature = (course) => (
    <div key={course.title} className="p-4 md:w-1/3 h-[270px]">
      <div className="flex rounded-lg h-full bg-[#2957a4] p-8 hover:shadow-lg flex-col  transition-transform !duration-500 !ease-in-out transform hover:translate-y-[-5px]">
        <div className="flex items-center mb-3">
          <h2 className="uppercase drop-shadow-lg shadow-white text-red-400 font-extrabold text-xl title-font">
            {course.title}
          </h2>
        </div>
        <div className="flex-grow">
          <p className="leading-relaxed text-base text-white">
            {course.description}
          </p>
          <Link className="mt-3 inline-flex items-center text-yellow-200 hover:text-white">
            Learn More
            <svg
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              className="w-4 h-4 ml-2"
              viewBox="0 0 24 24"
            >
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </Link>
        </div>
      </div>
    </div>
  );
  const handleWheel = (e) => {
    e.preventDefault();
    if (sliderRef.current) {
      const deltaX = e.deltaX;

      if (Math.abs(deltaX) > 5) {
        // Horizontal scrolling
        if (deltaX > 0) {
          sliderRef.current.slickNext();
        } else {
          sliderRef.current.slickPrev();
        }
      }
    }
  };

  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 mx-auto w-full overflow-x-auto">
        <h2 className="text-3xl font-bold mb-4 text-center text-red-600">
          Explore Our Courses
        </h2>
        <section className="text-gray-600 body-font">
          <div
            className="container px-5 pt-6 pb-16 mx-auto"
            onWheel={handleWheel}
          >
            <div className="slick-container">
              <Slider {...settings} className="slick-slider" ref={sliderRef}>
                {coursesData.map((course) => renderFeature(course))}
              </Slider>
            </div>
          </div>
        </section>
      </div>
    </section>
  );
};

export default CoursesSection;
