import AboutSection from "../../components/landingPage/about";
import CoursesSection from "../../components/landingPage/courses";
import Form from "../../components/landingPage/form";
import Testimonial from "../../components/landingPage/testimonials";

function LandingPage() {
  return (
    <div className="flex flex-col min-h-screen overflow-x-hidden">
      <main className="flex-grow"></main>
      <AboutSection />
      <hr className="mb-10" />
      <CoursesSection />
      <hr className="mb-10" />
      <Testimonial />
      <hr className="mb-10" />
      <Form />
    </div>
  );
}

export default LandingPage;
