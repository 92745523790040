/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Paper, Typography } from "@mui/material";
import { useState } from "react";
import LanguageFormWrapper from "../../components/floatingForm";

const Module = ({ module, description, isExpanded }) => {
  const bulletPoints = description.split("\n");

  return (
    <li className="mb-2">
      <Typography
        className="!text-xl !font-montserrat text-red-600"
        gutterBottom
      >
        {module}
      </Typography>
      {isExpanded && (
        <div className="mt-2">
          <ul className="list-disc pl-6">
            {bulletPoints.map((point, index) => (
              <li key={index} className="mb-2 !text-md text-[#2957a4]">
                {point}
              </li>
            ))}
          </ul>
        </div>
      )}
    </li>
  );
};

const CourseLevel = ({ level, modules, duration, setIsOpen }) => {
  const [isExpanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!isExpanded);
  };

  return (
    <Paper className="p-4 my-4 w-1/3 !transition-transform !duration-300 !ease-in-out !transform hover:!translate-y-[-5px] hover:!shadow-lg !rounded-2xl">
      <Typography
        className="!text-2xl !font-bold uppercase text-[#2957a4]"
        gutterBottom
      >
        Level {level}
      </Typography>
      <Typography variant="subtitle1" className="!font-montserrat" gutterBottom>
        Duration: {duration} hours
      </Typography>
      <ol>
        {modules.map(({ module, description }, index) => (
          <Module
            key={index}
            module={module}
            description={description}
            isExpanded={isExpanded}
          />
        ))}
      </ol>
      <div className="flex mt-6 mb-2 w-full !font-montserrat justify-between">
        <Button
          onClick={handleExpandClick}
          variant="outlined"
          size="small"
          className="mt-2 !text-[#2957a4] !font-montserrat hover:!text-red-600"
        >
          {isExpanded ? "Collapse Details" : "Expand Details"}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="mt-2 !bg-[#2957a4] !font-montserrat hover:!bg-red-600"
          onClick={() => {
            setIsOpen(true);
          }}
        >
          Ask for Price
        </Button>
      </div>
    </Paper>
  );
};

const SpokenEnglish = () => {
  const [isOpen, setIsOpen] = useState(false);
  const levels = [
    {
      level: 1,
      duration: 45,
      modules: [
        {
          module: "Greetings and Introductions",
          description:
            "Learn the basics of greetings, introductions, and common expressions.",
        },
        {
          module: "Everyday Conversations",
          description:
            "Engage in everyday conversations covering a range of topics.",
        },
        {
          module: "Numbers and Basic Information",
          description:
            "Understand and use numbers, and convey basic information effectively.",
        },
      ],
    },
    {
      level: 2,
      duration: 45,
      modules: [
        {
          module: "Travel and Leisure",
          description:
            "Explore vocabulary and phrases related to travel and leisure activities.",
        },
        {
          module: "Work and Career Conversations",
          description:
            "Enhance your communication skills in work and career-related discussions.",
        },
        {
          module: "Describing Experiences and Events",
          description:
            "Learn to express and describe experiences and events in detail.",
        },
      ],
    },
    {
      level: 3,
      duration: 45,
      modules: [
        {
          module: "Advanced Business Communication",
          description:
            "Develop proficiency in advanced business communication and negotiations.",
        },
        {
          module: "Academic Discussions and Debates",
          description:
            "Engage in academic discussions and debates, expanding your vocabulary.",
        },
        {
          module: "Cultural Awareness in Communication",
          description:
            "Understand cultural nuances and enhance communication awareness.",
        },
      ],
    },
  ];

  return (
    <div className="mt-8 px-16  text-gray-800">
      <Typography
        className="!text-3xl !text-center !font-adlam !mb-4 text-[#2957a4]"
        gutterBottom
      >
        Spoken English Course
      </Typography>

      <div className="flex space-x-6">
        {levels.map(({ level, modules, duration }) => (
          <CourseLevel
            key={level}
            level={level}
            modules={modules}
            duration={duration}
            setIsOpen={setIsOpen}
          />
        ))}
      </div>
      <LanguageFormWrapper isOpen={isOpen} setIsOpen={setIsOpen} />
      <div className="mt-8">
        <Typography
          className="!text-2xl !font-montserrat text-[#2957a4]"
          gutterBottom
        >
          Resources
        </Typography>
        <ul className="list-disc pl-6">
          <li>
            <a href="#" className="text-[#2957a4]">
              Spoken English Tips for Beginners
            </a>
          </li>
          <li>
            <a className="text-[#2957a4]" href="#">
              Improving Pronunciation: A Guide
            </a>
          </li>
          <li>
            <a className="text-[#2957a4]" href="#">
              Common English Phrases for Daily Conversations
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SpokenEnglish;
