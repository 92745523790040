import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const LanguageForm = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    mobile: "",
    message: "",
    email: "",
    courses: "",
  });

  const [isProcessing, setProcessing] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  console.log(formData);
  const handleExploreClick = () => {
    if (isProcessing) {
      // Avoid multiple submissions while processing
      return;
    }

    // Simple validation checks
    if (
      !formData.fullName ||
      !formData.mobile ||
      formData.mobile.length !== 10
    ) {
      toast.error("Please provide a valid name and a 10-digit mobile number.");
      return;
    }

    setProcessing(true);

    const templateParams = {
      to_email: "engworld21@gmail.com",
      to_name: "TLH",
      from_name: formData?.fullName,
      message: formData?.message,
      contact_number: formData?.mobile,
      email: formData?.email,
      course: formData?.courses,
    };

    emailjs
      .send("service_m5w9m1l", "template_c0xr13m", templateParams, {
        publicKey: "Sr3vgnBkm3u1lkDTI",
      })
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          toast.success("Enquiry sent successfully!");
          setFormData({
            fullName: "",
            mobile: null,
            message: "",
            email: "",
            course: "",
          });
        },
        (err) => {
          console.log("FAILED...", err);
          toast.error("Failed to send enquiry. Please try again.");
        }
      )
      .finally(() => {
        setProcessing(false);
      });
  };
  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 pb-10 mx-auto flex items-center justify-center flex-col">
        <div className="flex flex-col text-center w-full mb-12">
          <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-red-600">
            Request a Callback
          </h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base text-[#2957a4]">
            Fill in the form below, and our language experts will get back to
            you for a personalized language consultation.
          </p>
        </div>
        <div className="flex lg:w-2/3 w-full sm:flex-row flex-col mx-auto px-8 sm:space-x-4 sm:space-y-0 space-y-4 sm:px-0 items-end">
          <div className="relative flex-grow w-full">
            <label
              htmlFor="full-name"
              className="leading-7 text-sm text-gray-600"
            >
              Full Name
            </label>
            <input
              type="text"
              id="full-name"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              placeholder="Enter your full name"
              className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-transparent focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
            />
          </div>
          <div className="relative flex-grow w-full">
            <label htmlFor="number" className="leading-7 text-sm text-gray-600">
              Mobile
            </label>
            <input
              type="number"
              id="number"
              name="mobile"
              placeholder="Enter your mobile number"
              value={formData.mobile || ""}
              onChange={handleChange}
              className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-transparent focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
            />
          </div>
        </div>
        <div className="flex lg:w-2/3 w-full sm:flex-row flex-col mx-auto px-8 sm:space-x-4 sm:space-y-0 space-y-4 sm:px-0 items-end">
          <div className="relative flex-grow w-full">
            <label htmlFor="email" className="leading-7 text-sm text-gray-600">
              Email
            </label>
            <input
              type="text"
              id="email"
              name="email"
              placeholder="Enter your email address"
              value={formData.email}
              onChange={handleChange}
              className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-transparent focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
            />
          </div>
          <div className="relative flex-grow w-full">
            <label
              htmlFor="courses"
              className="leading-7 text-sm text-gray-600"
            >
              Courses
            </label>
            <select
              id="courses"
              name="courses"
              value={formData.courses || ""}
              onChange={handleChange}
              className="w-full h-[42px] bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-transparent focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
            >
              <option value="">Select a course</option>
              <option value="IELTS">IELTS</option>
              <option value="PTE">PTE</option>
              <option value="Spoken English">Spoken English</option>
              <option value="Business English">Business English</option>
              <option value="Professional English">Professional English</option>
              <option value="Grammar">Grammar</option>
            </select>
          </div>
        </div>
        <div className="lg:w-2/3 w-full">
          <div className="relative flex-grow w-full">
            <label
              htmlFor="message"
              className="leading-7 text-sm text-gray-600"
            >
              Message
            </label>
            <textarea
              id="message"
              name="message"
              placeholder="Enter a message"
              value={formData.message}
              onChange={handleChange}
              rows="3"
              className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-transparent focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out resize-none"
            ></textarea>
          </div>
        </div>
        <button
          className="text-white mt-10 bg-[#2957a4] border-0 py-2 px-8 focus:outline-none hover:bg-red-600 rounded text-lg"
          onClick={handleExploreClick}
          disabled={isProcessing}
        >
          {isProcessing ? "Processing..." : "Request Callback"}
        </button>
      </div>
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
    </section>
  );
};

export default LanguageForm;
