import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Footer from "./components/footer";
import Header from "./components/header";
import "./index.css";
import About from "./pages/about";
import Contact from "./pages/contact";
import Course from "./pages/course";
import IELTS from "./pages/ielts";
import LandingPage from "./pages/landingPage";
import PTE from "./pages/pte";
import SpokenEnglish from "./pages/spoken";
import GrammarCourse from "./pages/grammar";
import ProfessionalEnglishCourse from "./pages/businessEnglish";

function App() {
  return (
    <Router>
      <div className="flex flex-col min-h-screen font-montserrat">
        <Header />
        <main className="flex-grow ">
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/course" element={<Course />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/courses/ielts" element={<IELTS />} />
            <Route path="/courses/pte" element={<PTE />} />
            <Route path="/courses/grammar" element={<GrammarCourse />} />
            <Route path="/courses/spoken-english" element={<SpokenEnglish />} />
            <Route
              path="/courses/business-english"
              element={<ProfessionalEnglishCourse />}
            />
            <Route path="/courses/verbal-communication" element={<Course />} />
            {/* <Route path="*" element={<NotFound />} /> */}
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
